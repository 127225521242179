import { MDBContainer, MDBBtn, MDBIcon } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import {useOutletContext, useParams, useSearchParams} from 'react-router-dom'

import api from '../api'
function CommitLogPage() {
    const { id } = useParams()

    const {setOpenInWebUrl} = useOutletContext()

    const [commitInfo, setCommitInfo] = useState(null)
    const [commitLink, setCommitLink] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()

    const fetchCommitDetails = async() => {
        const token = searchParams.get("token")

        var filters = {}
        return (await api.tasks.getCommitInfo(id, filters, token)).data
    }

    const getOpenInWebUrl = () => {
        const params = new URLSearchParams({
            init_data: window.Telegram.WebApp.initData
        })
        return `${window.location.origin}/commit/${id}?${params}`
    }

    useEffect(() => {
        setOpenInWebUrl(getOpenInWebUrl())
        fetchCommitDetails().then((data) => {
            setCommitInfo(data.details)
            setCommitLink(data.gitlab_link)
        })

        const telegram = window.Telegram.WebApp
        try{
            telegram.onEvent('mainButtonClicked', () => telegram.close())
            telegram.MainButton.text = "Close"
            telegram.MainButton.show()
        }
        catch(ex){
            console.log("useEffect, CommitLogPage", ex)
        }

        return () => {
            telegram.MainButton.hide()
        }
    }, [])

    return (
        <MDBContainer>
            <h3>Information about commit {id}</h3>
            {
                commitLink !== null &&
                <MDBBtn className={'text-white mb-3'} target={'_blank'} href={commitLink}>
                    GitLab <MDBIcon fas icon="external-link-alt" />
                </MDBBtn>
            }
            <div className='pre-wrap break-word tg-text'>
                {commitInfo}
            </div>
        </MDBContainer>
    )
}

export default CommitLogPage
