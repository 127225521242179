import {useEffect, useState} from "react";
import {fetchAccessUsers} from "../requests/accessUsers";
import {MDBToast} from "mdb-react-ui-kit";
import {useOutletContext} from "react-router-dom";
import api from "../api";
import AccessUsers from "../components/accessUsers/accessUsers";
import useMe from "../hooks/useMe";


export default function AccessUsersPage() {
    const {setHeaderText, setIsSecondaryBg, setOpenInWebUrl} = useOutletContext()

    const [accessUsers, setAccessUsers] = useState([])
    const [alert, setAlertRaw] = useState(null)
    const [me] = useMe()

    const [localisedTexts, setLocalisedTexts] = useState({})

    const lang = me.lang

    const setAlert = (text, errorOrSuccess) => {
        setAlertRaw({
            date: new Date(),
            errorOrSuccess, text,
        })
    }

    useEffect(() => {
        setIsSecondaryBg(true)
    }, [])

    useEffect(() => {
        fetchAccessUsers().then(data => {
            if (!data.error) {
                setAccessUsers(data)
            } else {
                setAccessUsers([])
                setAlert(data.error, 'error')
            }
        })
        setOpenInWebUrl(getOpenInWebUrl())
    }, [])

    useEffect(() => {
        setHeaderText(localisedTexts.header)
    }, [localisedTexts.header])

    useEffect(() => {
        if (!lang) return

        api.instance.defaults.headers.common['Accept-Language'] = lang
        api.accessUsers.getLocalisation().then(
            response => {
                if (response.status === 200) {
                    setLocalisedTexts(response.data)
                }
            }
        )
    }, [lang])

    const getOpenInWebUrl = () => {
        const params = new URLSearchParams({
            init_data: window.Telegram.WebApp.initData
        })
        return `${window.location.origin}/accessUsers?${params}`
    }

    const updateAccessUser = (id, data) => {
        api.accessUsers.updateAccessUser(id, data).then(
            response => {
                if (response.status === 200) {
                    setAlert(response.data.message, 'success')
                    setAccessUsers(accessUsers.map(accessUser => {
                        if (accessUser.id === id) {
                            return {...accessUser, ...response.data.data}
                        }
                        return accessUser
                    }))
                } else {
                    setAlert(response.data.detail, 'error')
                }
            }
        ).catch(error => {
            console.log(error)
            setAlert(error.response.data.detail, 'error')
        })
    }

    const createAccessor = (accessUserID, accessorUserID) => {
        api.accessUsers.createAccessor(accessUserID, accessorUserID).then(
            response => {
                if (response.status === 200) {
                    setAlert(response.data.message, 'success')
                    setAccessUsers(accessUsers.map(accessUser => {
                        if (accessUser.id === accessUserID) {
                            let accessors;
                            if (accessorUserID !== me.id) {
                                accessors = [...accessUser.accessors, response.data.accessor]
                            } else {
                                accessors = [response.data.accessor, ...accessUser.accessors]
                            }
                            return {...accessUser, accessors: accessors}
                        }
                        return accessUser
                    }))
                } else {
                    setAlert(response.data.detail, 'error')
                }
            }
        ).catch(error => {
            console.log(error)
            if (error.response) {
                setAlert(error.response.data.detail, 'error')
            }
        })
    }

    const updateAccessor = (id, data) => {
        api.accessUsers.updateAccessor(id, data).then(
            response => {
                if (response.status === 200) {
                    setAlert(response.data.message,'success')
                    setAccessUsers(accessUsers.map(accessUser => {
                        return {...accessUser, accessors : accessUser.accessors.map(accessor => {
                            if (accessor.id === id) {
                                return {...accessor, ...response.data.data}
                            }
                            return accessor
                        })}
                    }))
                } else {
                    setAlert(response.data.detail, 'error')
                }
            }
        ).catch(error => {
            console.log(error)
            setAlert(error.response.data.detail, 'error')
        })
    }

    const deleteAccessUser = id => {
        api.accessUsers.deleteAccessUser(id).then(response => {
            if (response.status === 200) {
                setAlert(response.data.message,'success')
                setAccessUsers(accessUsers.filter(accessUser => accessUser.id !== id))
            } else {
                setAlert(response.data.detail, 'error')
            }
        }).catch(error => {
            console.log(error)
            setAlert(error.response.data.detail, 'error')
        })
    }

    const deleteAccessor = id => {
        api.accessUsers.deleteAccessor(id).then(response => {
            if (response.status === 200) {
                setAlert(response.data.message, 'success')
                setAccessUsers(accessUsers.map(accessUser => {
                    return {...accessUser, accessors: accessUser.accessors.filter(accessor => accessor.id !== id)}
                }))
            }
        })
    }

    return (<>
        {alert && (
            <MDBToast
                key={alert.date.toString()}
                color={alert.errorOrSuccess === 'error' ? 'danger' : 'success'}
                position={'top-right'}
                show
                autohide
                delay={5000}
                appendToBody
                headerContent={
                  <strong className={'me-auto'}>
                      {alert.errorOrSuccess.toLowerCase()}
                  </strong>
                }
                bodyContent={alert.text}
                onHide={() => setAlertRaw(null)}
              />
        )}
        <AccessUsers
            localisedTexts={localisedTexts}
            accessUsers={accessUsers}
            updateAccessUser={updateAccessUser}
            createAccessor={createAccessor}
            updateAccessor={updateAccessor}
            deleteAccessUser={deleteAccessUser}
            deleteAccessor={deleteAccessor}
        />
    </>)
}
