import React, {useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {
    MDBNavbar,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBContainer,
    MDBIcon, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModal, MDBBtn,
} from 'mdb-react-ui-kit'
import logo from '../../logo.svg'

function Header( { openInWebUrlRef, isTelegram, reload, setReload, needShort, headerText } ) {
    const location = useLocation()
    const [showInfo, setShowInfo] = useState()

    const openInBrowser = () => {
        window.Telegram.WebApp.openLink(openInWebUrlRef.current)
    }

    const isHomePage = location.pathname === '/' || location.pathname === ''

    if (location.pathname === '/timezonePicker') {
        return null
    }

    return (
        <header>
            <MDBNavbar expand='lg'>
                <MDBContainer fluid>
                    <div className="d-flex">
                        <Link to="/" className="navbar-brand">
                            <img src={logo} alt="Logo" width="38" height="38" />
                        </Link>
                        {
                            isTelegram
                            ?
                                <MDBNavbarNav right className='align-self-center'>
                                    <MDBNavbarItem active>
                                        <MDBNavbarLink aria-current='page' onClick={() => openInBrowser()} style={{cursor: "pointer"}}>
                                            Open in Web
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                </MDBNavbarNav>
                            :
                            null

                        }
                    </div>
                    {headerText && (
                        <h3>{headerText}</h3>
                    )}
                    <div className="d-flex justify-content-center my-2">
                        {isHomePage && needShort && (
                            <>
                                {/* reload btn */}
                                <MDBBtn className={'btn-floating me-2'} onClick={() => {
                                    setShowInfo(true)
                                }}>
                                    <MDBIcon fas icon="info" size={'xl'} />
                                </MDBBtn>
                                <MDBBtn className={'btn-floating'} onClick={() => {
                                    setReload(reload+1)
                                }}>
                                    <MDBIcon fas icon="sync" size={'xl'} />
                                </MDBBtn>
                            </>
                        )}
                    </div>
                </MDBContainer>
            </MDBNavbar>
            <MDBModal show={showInfo} setShow={setShowInfo} tabIndex='-1' className={'tg-modal'}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle><MDBIcon fas icon="info" className={'me-2'} />{'Columns description'}</MDBModalTitle>
                      <a href={"#"} onClick={() => setShowInfo(false)}>
                          <MDBIcon fas icon="times" size={'2x'}/>
                      </a>
                    </MDBModalHeader>
                    <MDBModalBody style={{
                        whiteSpace: 'pre-wrap',
                        lineHeight: '90%'
                    }}>
                        {'Name=\nUser name\nGroup name'}
                        <br/>
                        <br/>
                        {'Info=\nL+L-\nF+F-F*\nTrello\nSlots'}
                        <br/>
                        <br/>
                        {'Task=\nmm-dd[hh:mm-hh:mm time today month] tasktime | task commets'}
                        <br/>
                        <br/>
                        {'help =>'}
                        <a className={'ms-1'} target={'_blank'} href={'https://maximronshin.t.me'}>
                            {'@maximronshin'}
                        </a>
                    </MDBModalBody>
                </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
        </header>
    )
}

export default Header
