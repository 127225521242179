import React from "react";
import {MDBBtn} from "mdb-react-ui-kit";

import {getHoverButton, getHoverText} from "./hover";
import useIsTg from "./hooks/isTg";


export function TgButton({className, onClick, children, buttonRef, ...props}) {
    const isTg = useIsTg()
    const buttonProps = {...props, ...(isTg ? getHoverButton('--tg-theme-button-color') : {})}
    return (
        <MDBBtn
            ref={buttonRef}
            className={`p-2 ${className ? className: ''} ${isTg ? 'tg-button' : ''}`}
            onClick={onClick ? onClick: () => {}}
            {...buttonProps}
        >
            {children}
        </MDBBtn>
    )
}

export function TgLink({className, children, href, ...props}) {
    const isTg = useIsTg()
    return (
        <a
            className={`tg-link ${className ? className: ''}`}
            href={href || '#'}
            {...(isTg ? getHoverText('--tg-theme-link-color') : {})}
            {...props}
        >
            {children}
        </a>
    )
}