import {
    MDBAutocomplete,
    MDBBtn,
    MDBCard,
    MDBCardBody, MDBCardFooter,
    MDBCheckbox,
    MDBCol,
    MDBIcon,
    MDBInputGroup, MDBPopconfirm, MDBPopconfirmMessage,
    MDBRow
} from "mdb-react-ui-kit";
import {useEffect, useRef, useState} from "react";

import './style.scss'
import api from "../../api";
import {TgButton, TgLink} from "../../tgTheme";


function AccessorUserSelect( { label, accessUserID, createAccessor, noResultsText } ) {
    const [value, setValue] = useState('')

    return (
        <MDBAutocomplete
            value={value}
            wrapperClass={'tg-input'}
            labelClass={'tg-text'}
            className={'tg-input rounded-start'}
            required
            label={label}
            threshold={3}
            displayValue={user => user.name}
            dataFilter={async (search) => {
                if (search.length <= 2) {
                    return []
                }
                const response = await api.accessUsers.getUsersToAddAccessor(accessUserID, search)
                if (response.status === 200) {
                    return response.data
                } else {
                    return []
                }
            }}
            onSelect={(user) => {
                createAccessor(accessUserID, user.user_id)
                setValue('')
            }}
            itemContent={(user) => (
                <div className={'w-100 text-center'}>
                    {user.name && (
                        <span>{user.name}</span>
                    )}
                    {user.username && (
                        <small><br/>@{user.username}</small>
                    )}
                    {user.email && (
                        <small><br/>{user.email}</small>
                    )}
                </div>
            )}
            onChange={e => setValue(e.target.value)}
            noResults={noResultsText}
        />
    )
}


function Accessor( { localisedTexts, accessor, updateAccessor, deleteAccessor } ) {

    const onChange = e => {
        const data = {}
        data[e.target.name] = e.target.checked
        updateAccessor(accessor.id, data)
    }

    return (
        <MDBRow key={`accessor-${accessor.id}`}>
            <MDBCol size={4} className={'pe-0'}>
                {accessor.name}
            </MDBCol>

            <MDBCol size={3} className={'p-0'}>
                <MDBCheckbox
                    onChange={onChange}
                    className={'tg-checkbox'}
                    name={'notify_commits'}
                    label={
                        <span>
                            <MDBIcon far icon="bell" />
                            <span className={'ms-1'}>{localisedTexts.git_label}</span>
                        </span>
                }
                    defaultChecked={accessor.notify_commits}
                />
            </MDBCol>
            <MDBCol size={4} className={'ps-0 pe-1'}>
                <MDBCheckbox
                    onChange={onChange}
                    className={'tg-checkbox'}
                    name={'notify_slots'}
                    label={
                        <span>
                            <MDBIcon far icon="bell" />
                            <span className={'ms-1'}>{localisedTexts.slots_label}</span>
                        </span>
                    }
                    defaultChecked={accessor.notify_slots}
                />
            </MDBCol>
            <MDBCol size={1} className={'text-start p-0'}>
                <a
                    href={'#'}
                    onClick={e => {
                        e.preventDefault()
                        deleteAccessor(accessor.id)
                    }}
                    className={"text-danger"}
                >
                    <MDBIcon far icon="trash-alt" />
                </a>
            </MDBCol>
        </MDBRow>
    )
}


function AccessUserCard(
    {
        localisedTexts,
        accessUser,
        updateAccessUser,
        createAccessor,
        updateAccessor,
        deleteAccessUser,
        deleteAccessor,
    }
) {
    const nameRef = useRef(null)
    const emailRef = useRef(null)
    const sheetLinkRef = useRef(null)
    const mustCommitRef = useRef(null)

    const saveSheetUrlButtonRef = useRef(null)

    const saveField = (fieldName, value) => {
        const data = {}
        data[fieldName] = value
        updateAccessUser(accessUser.id, data)
    }

    const getOnSave = ref => e => {
        e.preventDefault()
        const el = ref.current
        saveField(el.name, el.value)
        el.blur()
    }

    const onMustCommitCheck = e => {
        saveField('must_commit', e.target.checked)
    }

    const isInputsRefsFilled = !!(nameRef.current && emailRef.current && sheetLinkRef.current && mustCommitRef.current)

    useEffect(() => {
        nameRef.current.value = accessUser.name
        nameRef.current.classList.add('active')
        emailRef.current.value = accessUser.email
        sheetLinkRef.current.value = accessUser.sheet_url
        mustCommitRef.current = accessUser.must_commit
    }, [accessUser.email, accessUser.must_commit, accessUser.name, accessUser.sheet_url, isInputsRefsFilled])

    const removeActive = (e) => {
        if (!e.target.value) {
            e.target.classList.remove('active');
        }
    }

    return (
        <MDBCard id={accessUser.id} className={'h-100 tg-card'}>
            <MDBCardBody className={'p-2 pt-3 access-user-card-box'}>
                <div className={'access-user-card-content'}>
                    <form onSubmit={e => e.preventDefault()}>
                        <MDBInputGroup
                            textBefore={localisedTexts.name_label} noBorder
                            textClass={'access-user-card-input-text tg-text'}
                        >
                            <input
                                ref={nameRef} type={'text'} name={'name'}
                                placeholder={localisedTexts.name_placeholder}
                                className={'form-control rounded-start tg-input'}
                            />
                            <TgButton className={'px-3'} type={'button'} onClick={getOnSave(nameRef)}>
                                <MDBIcon fas icon="check" />
                            </TgButton>
                        </MDBInputGroup>

                        <MDBInputGroup
                            textBefore={localisedTexts.username_label}
                            noBorder className={'mt-2'}
                            textClass={'access-user-card-input-text tg-text'}
                        >
                            <input
                                className={'form-control rounded-start tg-secondary-bg'}
                                readOnly value={accessUser.username ? `@${accessUser.username}` : '-'}
                            />
                            <TgButton className={'px-3'} type={'button'} onClick={(e) => {
                                e.preventDefault()
                                if (accessUser.username) {
                                    const link = `https://${accessUser.username}.t.me`
                                    if (window.Telegram.openTelegramLink) {
                                        window.Telegram.openTelegramLink(link)
                                    } else {
                                        window.open(link, '_blank')
                                    }
                                }
                            }}>
                                <MDBIcon fas icon="external-link-alt" />
                            </TgButton>
                        </MDBInputGroup>

                        <MDBInputGroup
                            textBefore={localisedTexts.email_label} noBorder className={'mt-2'}
                            textClass={'access-user-card-input-text tg-text'}
                        >
                            <input
                                ref={emailRef}
                                name={'email'}
                                placeholder={localisedTexts.email_label}
                                className={'form-control rounded-start tg-input'}
                                onChange={removeActive}
                            />
                            <TgButton className={'px-3'} type={'button'}  onClick={getOnSave(emailRef)}>
                                <MDBIcon fas icon="check" />
                            </TgButton>
                        </MDBInputGroup>

                        <MDBInputGroup
                            textBefore={localisedTexts.google_label} noBorder className={'mt-2'}
                            textClass={'access-user-card-input-text tg-text pb-0'}
                            aria-describedby={'clear-button'}
                        >
                            <input
                                ref={sheetLinkRef}
                                placeholder={localisedTexts.google_placeholder}
                                onChange={removeActive}
                                name={'sheet_url'}
                                className={'form-control rounded-start tg-input'}
                            />

                            <MDBBtn color={'info'} className={'px-3'} onClick={(e) => {
                                e.preventDefault()
                                if (sheetLinkRef.current.value) {
                                    window.open(sheetLinkRef.current.value)
                                }
                            }}>
                                <MDBIcon fas icon="external-link-alt" />
                            </MDBBtn>
                            <TgButton
                                buttonRef={saveSheetUrlButtonRef}
                                className={'px-3'} type={'button'}
                                onClick={getOnSave(sheetLinkRef)}
                            >
                                <MDBIcon fas icon="check" />
                            </TgButton>
                        </MDBInputGroup>
                        {accessUser.sheet_url && (
                            <div id={'clear-button'} className={'w-100 text-center'}>
                                <TgLink onClick={e => {
                                    e.preventDefault()
                                    sheetLinkRef.current.value = ''
                                    getOnSave(sheetLinkRef)(e)
                                }}>
                                    <small>{localisedTexts.clear_google_sheet_button}</small>
                                </TgLink>
                            </div>
                        )}
                    </form>
                    <div className={'d-flex justify-content-between mt-4 mb-2'}>
                        <div className={'d-inline-block'}>
                            <div className={'d-flex align-items-center h-100 '}>
                                <h6 className={'mb-0'}><b>{localisedTexts.accessors_header}</b></h6>
                            </div>
                        </div>
                        <div className={'d-inline-block pe-2'}>
                            <MDBInputGroup textBefore={<MDBIcon icon={'plus'}/> } textClass={'tg-text'} noBorder>
                                <AccessorUserSelect
                                    label={localisedTexts.accessor_name_placeholder}
                                    accessUserID={accessUser.id}
                                    createAccessor={createAccessor}
                                    noResultsText={localisedTexts.no_results_text}
                                />
                            </MDBInputGroup>
                        </div>
                    </div>
                    {accessUser.accessors.map(accessor => {
                        return (
                            <Accessor
                                key={accessor.id} accessor={accessor}
                                localisedTexts={localisedTexts}
                                updateAccessor={updateAccessor}
                                deleteAccessor={deleteAccessor}
                            />
                        )
                    })}
                </div>
                <MDBCardFooter className={'px-2 d-flex access-user-card-header pe-1 justify-content-between'}>
                    <MDBCheckbox
                        wrapperClass={'me-2 d-flex align-items-center'}
                        className={'tg-checkbox'}
                        label={localisedTexts.must_commit_label}
                        name={'must_commit'}
                        inputRef={mustCommitRef}
                        onChange={onMustCommitCheck}
                        defaultChecked={accessUser.must_commit}
                    />
                    <MDBPopconfirm
                        btnChildren={<>
                            <MDBIcon far icon="trash-alt" />
                            <span className={'ms-2'}>{localisedTexts.delete_button}</span>
                        </>}
                        className={'tg-popconfirm'}
                        cancelBtnText={localisedTexts.cancel_delete_button}
                        confirmBtnText={localisedTexts.confirm_delete_button}
                        btnClassName={'btn-danger'}
                        confirmBtnClasses={'btn-danger'}
                        cancelBtnClasses={'btn-secondary'}
                        onConfirm={() => {
                            deleteAccessUser(accessUser.id)
                        }}
                        placement={'top-start'}
                    >
                        <MDBPopconfirmMessage>{localisedTexts.confirm_delete_message}</MDBPopconfirmMessage>
                    </MDBPopconfirm>
                </MDBCardFooter>
            </MDBCardBody>
        </MDBCard>
    )
}


export default function AccessUsers(
    {
        localisedTexts,
        accessUsers,
        updateAccessUser,
        createAccessor,
        updateAccessor,
        deleteAccessUser,
        deleteAccessor,
    } ) {
    return (
        <MDBRow className={'align-items-stretch justify-content-center justify-content-sm-start w-100 m-0'}>
            {accessUsers.map(accessUser => {
                return (
                    <MDBCol key={accessUser.id} size={12} sm={6} lg={4} xl={3} className={'mt-3'}>
                        <AccessUserCard
                            localisedTexts={localisedTexts}
                            accessUser={accessUser}
                            updateAccessUser={updateAccessUser}
                            createAccessor={createAccessor}
                            updateAccessor={updateAccessor}
                            deleteAccessUser={deleteAccessUser}
                            deleteAccessor={deleteAccessor}
                        />
                    </MDBCol>
                )
            })}
        </MDBRow>
    )
}
