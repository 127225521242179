import React, { useEffect, useState } from 'react'
import {useOutletContext, useSearchParams} from 'react-router-dom'

import TasksTable from '../components/tasks-table/TasksTable'
import { getColumns } from '../utils/table'
import {
    fetchDates,
    fetchTasks,
    fetchData,
    fetchUsers,
    fetchGroups, fetchOwners,
} from '../requests/table'
import api from "../api";
import {MDBToast} from "mdb-react-ui-kit";
import {useOwnerID} from "../hooks/ownerID";


export default function HomePage() {
    const {reload, setOpenInWebUrl, setNeedShort, initData} = useOutletContext()

    const [searchParams, setSearchParams] = useSearchParams()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [columns, setColumns] = useState([])

    const today = new Date().toISOString().slice(0, 10)

    const [errors, setErrors] = useState({
        isErrors: false,
        usersError: "",
        tasksError: "",
        groupsError: "",
        ownerError: "",
        datesError: "",
        dataError: "",
    })

    const [alertObj, setAlert] = useState(null)

    const [isInited, setIsInited] = useState(false)

    const [allowedOwners, setAllowedOwners] = useState(null)
    const [allowedUsers, setAllowedUsers] = useState(null)
    const [allowedTasks, setAllowedTasks] = useState(null)
    const [allowedGroups, setAllowedGroups] = useState(null)
    const [minMaxDates, setMinMaxDates] = useState({})

    const [ownerID, {setOwnerID}] = useOwnerID(null)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedTasks, setSelectedTasks] = useState([])
    const [selectedGroups, setSelectedGroups] = useState([])

    const [data, setData] = useState([])

    const [filters, setFilters] = useState({
        start_date: today,
        end_date: today,
        chat_id:  null,
    })

    const onFetchOwners = data => {
        if ( data.error ) {
            setErrors(prevState => ({...prevState, isErrors: true, ownerError: data.error}))
        } else {
            setAllowedOwners(data)
            if (data.length === 1) {
                setOwnerID(data[0].id)
            }
        }
    }

    const onFetchGroups = data => {
        if(data.error){
            setErrors({...errors, isErrors: true, groupsError: data.error})
            setAllowedGroups([])
        }
        else{
            setAllowedGroups(data)
        }
    }

    const onFetchDates = data => {
        if(data.error){
            setErrors({...errors, isErrors: true, datesError: data.error})
            setMinMaxDates([])
        }
        else{
            setMinMaxDates(data)
        }
    }

    const onFetchTasks = data => {
        if(data.error){
            setErrors({...errors, isErrors: true, tasksError: data.error})
            setAllowedTasks([])
        }
        else{
            setAllowedTasks(data)
        }
    }

    const onFetchUsers = data => {
        if(data.error){
            setErrors({...errors, isErrors: true, usersError: data.error})
            setAllowedUsers([])
        }
        else{
            setAllowedUsers(data)
        }
    }

    const onFetchData = data => {
        if(data.error){
            setErrors({...errors, isErrors: true, dataError: data.error})
            setData([])
        }
        else{
            setData(data)
        }
    }

    const onStartDateFilterChange = (value) => {
        const isValid = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value)
        if(isValid){
            setFilters({...filters, "start_date": value})
        }
    }

    const onEndDateFilterChange = (value) => {
        const isValid = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value)
        if(isValid){
            setFilters({...filters, "end_date": value})
        }
    }

    const onUsersFilterChange = (e) => {
        const data = JSON.parse(JSON.stringify(e))
        console.log('here')
        setSelectedUsers(data.map(i => i.value))
    }

    const onOwnerFilterChange = (e) => {
        setOwnerID(e.value)
    }

    const onTasksFilterChange = (e) => {
        const data = JSON.parse(JSON.stringify(e))
        setSelectedTasks(data.map(i => i.value))
    }

    const onGroupsFilterChange = (e) => {
        const data = JSON.parse(JSON.stringify(e))
        setSelectedGroups(data.map(i => i.value))
    }

    const updateData = (slotIDX, generateNewRow) => {
        setData(data.map((row, idx) => {
            if (idx === slotIDX) {
                return generateNewRow(row)
            }
            return row
        }))
    }

    const resolveError = (error) => {
        console.log(error)
        setAlert({
            success: false,
            text: error.response.data.detail ? error.response.data.detail : 'Error while saving data'
        })
    }

    const saveTaskName = (slotIDX, name) => {
        const slotID = data[slotIDX].slot_id
        api.slots.updateName(slotID, name).then(
            response => {
                const result = response.data
                updateData(slotIDX, (row) => {
                    return {...row, task_name: result.new_name}
                })
            setAlert({
                    success: true,
                    text: 'You changed task name',
                    date: new Date(),
                })
            }
        ).catch(resolveError)
    }

    const saveTrelloLink = (slotIDX, trelloLink) => {
        const slotID = data[slotIDX].slot_id
        api.slots.updateTrello(slotID, trelloLink).then(
            response => {
                const result = response.data
                updateData(slotIDX, (row) => {
                    return {
                        ...row,
                        trello_link: result.new_trello_link,
                        task_name: result.new_task_name,
                    }
                })
                setAlert({
                    success: true,
                    text: 'You changed task',
                    date: new Date(),
                })
            }
        ).catch(resolveError)
    }

    const saveComment = (slotIDX, comment) => {
        const slotID = data[slotIDX].slot_id
        api.slots.updateComment(slotID, comment).then(
            response => {
                const result = response.data
                updateData(slotIDX, (row) => {
                    return {...row, comments: result.new_comment}
                })
            setAlert({
                    success: true,
                    text: 'You changed comment',
                    date: new Date(),
                })
            }
        ).catch(resolveError)
    }


    const getOpenInWebUrl = () => {
        const params = new URLSearchParams({
            init_data: window.Telegram.WebApp.initData || initData,
            ...filters,
        })

        for (const i of selectedUsers){
            params.append("users", i)
        }

        for (const i of selectedTasks){
            params.append("tasks", i)
        }

        for (const i of selectedGroups){
            params.append("groups", i)
        }
        return `${window.location.origin}?${params}`
    }

    const windowWidthListener = () => {
            setWindowWidth(window.innerWidth)
        }

    const isAllowedOwnersExists = allowedOwners && allowedOwners.length > 0 > 0

    useEffect(() => {
        if (isAllowedOwnersExists && ownerID === null) {
            setOwnerID(allowedOwners[0].id)
        }
    }, [ownerID, isAllowedOwnersExists])

    useEffect(() => {
        setOpenInWebUrl(getOpenInWebUrl())
        window.removeEventListener('resize', windowWidthListener)
        window.addEventListener('resize', windowWidthListener)

        setIsInited(true)

    }, [reload])

    useEffect(() => {
        if (!isInited) return

        const onStartTasks = searchParams.getAll("tasks") !== null ? searchParams.getAll("tasks").map(i => parseInt(i)) : null
        const onStartUsers = searchParams.getAll("users") !== null ? searchParams.getAll("users").map(i => parseInt(i)) : null
        const onStartGroups = searchParams.getAll("groups") !== null ? searchParams.getAll("groups").map(i => parseInt(i)) : null
        const onStartStartDate = searchParams.get("start_date") !== null ? searchParams.get("start_date") : null
        const onStartEndDate = searchParams.get("end_date") !== null ? searchParams.get("end_date") : null
        const urlChatId = searchParams.get("chat_id")

        if (onStartTasks && onStartTasks.length) setSelectedTasks(onStartTasks)
        if (onStartUsers && onStartUsers.length) setSelectedUsers(onStartUsers)
        if (onStartGroups && onStartGroups.length) setSelectedGroups(onStartGroups)

        setFilters({
            start_date: onStartStartDate || filters.start_date,
            end_date: onStartEndDate || filters.end_date,
            chat_id: urlChatId || filters.chat_id,
        })

        if (searchParams.has('tasks')) searchParams.delete('tasks')
        if (searchParams.has('users')) searchParams.delete('users')
        if (searchParams.has('groups')) searchParams.delete('groups')
        if (searchParams.has('start_date')) searchParams.delete('start_date')
        if (searchParams.has('end_date')) searchParams.delete('end_date')
        if (searchParams.has('chat_id')) searchParams.delete('chat_id')
        setSearchParams(searchParams)
    }, [isInited, searchParams.toString()])

    useEffect(() => {

    })

    useEffect(() => {
        if (!isInited) return
        fetchOwners(filters.chat_id).then(onFetchOwners)
    }, [
        reload, isInited, filters.chat_id
    ])

    useEffect(() => {
        if ( !isInited || !ownerID ) return
        fetchGroups(ownerID,{chat_id: filters.chat_id}).then(onFetchGroups)
    }, [
        reload, isInited, ownerID,
        filters.chat_id,
    ])

    useEffect(() => {
        if ( !isInited || !ownerID ) return
        fetchTasks(ownerID, selectedUsers, selectedGroups, filters).then(onFetchTasks)
    }, [
        reload, isInited, ownerID,
        JSON.stringify(selectedUsers),
        JSON.stringify(selectedGroups),
        JSON.stringify(filters),
    ])

    useEffect(() => {
        if ( !isInited || !ownerID ) return
        fetchUsers(ownerID, selectedTasks, selectedGroups, filters).then(onFetchUsers)
    }, [
        reload, isInited, ownerID,
        JSON.stringify(selectedTasks),
        JSON.stringify(selectedGroups),
        JSON.stringify(filters),
    ])

    useEffect(() => {
        if ( !isInited || !ownerID ) return
        fetchDates(ownerID, selectedUsers, selectedTasks, selectedGroups, {chat_id: filters.chat_id}).then(onFetchDates)
    }, [
        reload, isInited, ownerID,
        JSON.stringify(selectedUsers),
        JSON.stringify(selectedTasks),
        JSON.stringify(selectedGroups),
        filters.chat_id,
    ])

    useEffect(() => {
        if ( !isInited || !ownerID ) {
            return
        }
        setOpenInWebUrl(getOpenInWebUrl())
        fetchData(ownerID, selectedUsers, selectedTasks, selectedGroups, filters).then(onFetchData)
    }, [
        reload, isInited, ownerID,
        JSON.stringify(selectedUsers),
        JSON.stringify(selectedTasks),
        JSON.stringify(selectedGroups),
        JSON.stringify(filters),
    ])

    useEffect(() => {
        setNeedShort(!!ownerID)
    }, [ownerID])

    useEffect(() => {
        setColumns(getColumns())
    }, [windowWidth])

    return (
        <>
            {alertObj !== null && (
                <MDBToast
                    key={alertObj.date}
                    className={'word-wrap-pre-line'}
                    color={alertObj.success === true ? 'success' : 'danger'}
                    stacking show width={300}
                    autohide delay={5000} appendToBody position={'top-right'}
                    onHide={() => {
                        setAlert(null)
                    }}
                    headerClasses={'w-100'}
                    headerContent={(
                        <h6 className={'w-100 m-0'}>
                            {alertObj.success === true ? 'Success' : 'Error'}
                        </h6>
                    )}
                    bodyContent={alertObj.text}
                />
            )}
            <div className="App">
                {data !== undefined
                ?
                <TasksTable
                    ownerID={ownerID}
                    selectedGroups={selectedGroups}
                    users={selectedUsers}
                    tasks={selectedTasks}
                    data={data}
                    allowedOwners={allowedOwners}
                    allowedGroups={allowedGroups}
                    allowedUsers={allowedUsers}
                    allowedTasks={allowedTasks}
                    minMaxDates={minMaxDates}
                    onOwnerFilterChange={onOwnerFilterChange}
                    onGroupsFilterChange={onGroupsFilterChange}
                    onUsersFilterChange={onUsersFilterChange}
                    onTasksFilterChange={onTasksFilterChange}
                    onStartDateFilterChange={onStartDateFilterChange}
                    onEndDateFilterChange={onEndDateFilterChange}
                    filters={filters}
                    errors={errors}
                    columns={columns}
                    saveTrelloLink={saveTrelloLink}
                    saveComment={saveComment}
                    saveTaskName={saveTaskName}
                />
                :
                    null
                }
            </div>
        </>
    )
}
