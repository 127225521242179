import { BrowserRouter, Route, Routes } from "react-router-dom"

import Layout from './components/base/Layout'
import HomePage from './pages/HomePage'
import CommitLogPage from './pages/CommitLogPage'
import AccessUsersPage from "./pages/accessUsersPage";
import TimezonePickerPage from "./pages/TimezonePickerPage";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route path={'commit/:id'} element={<CommitLogPage />} />
                    <Route path={'accessUsers'} element={<AccessUsersPage/>}/>
                    <Route path={'timezonePicker'} element={<TimezonePickerPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}


export default App
