import {
    MDBBtn,
    MDBCol,
    MDBIcon,
    MDBModal, MDBModalBody,
    MDBModalContent,
    MDBModalDialog, MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBRow,
} from "mdb-react-ui-kit";
import {cloneElement, useEffect, useRef, useState} from "react";


function EditModal( { text, isEdit, setIsEdit, close, saveValue, plainText, editInput, editHeader, saveButton, cancelButton } ) {

    const inputRef = useRef()

    return (
            <>
                {text ? plainText : (
                    cloneElement(editInput, {
                        onFocus: () => {
                            setIsEdit(true)
                            setInterval(() => {
                                if (inputRef.current !== null) {
                                    inputRef.current.focus()
                                }
                            }, 1)
                        },
                    })
                )}
                <MDBModal show={isEdit} setShow={setIsEdit} tabIndex='-1' className={'tg-modal'}>
                <MDBModalDialog>
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>{editHeader}</MDBModalTitle>
                      <a href={"#"} onClick={close}><MDBIcon fas icon="times" size={'2x'}/></a>
                    </MDBModalHeader>
                    <MDBModalBody>{cloneElement(editInput, {
                        ref: inputRef,
                    })}</MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color='danger' onClick={close}>
                            {cancelButton}
                        </MDBBtn>
                        <MDBBtn onClick={saveValue}>{saveButton}</MDBBtn>
                    </MDBModalFooter>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
            </>
        )
}


function EditInput( { isEdit, close, value, saveValue, plainText, editInput, editEmptyByDefault, text } ) {
    if (isEdit === false && !(editEmptyByDefault === true && text === '')) {
        return plainText
    }

    return (
        <MDBRow className={'d-flex justify-content-center align-items-center'}>
            <MDBCol size={value === '' && isEdit === false ? 12 : 10} className={'pe-1'}>
                {editInput}
            </MDBCol>
            {(value !== '' || isEdit === true) && (
                <>
                    <MDBCol size={1} className={'ps-1 pe-1 text-center'}>
                        <a href={"#"} onClick={saveValue}><MDBIcon fas icon="check"/></a>
                    </MDBCol>
                        <MDBCol size={1} className={'ps-1 text-center'}>
                        <a href={"#"} onClick={close}><MDBIcon fas icon="times" /></a>
                    </MDBCol>
                </>
            )}
        </MDBRow>
    )
}


export default function EditableText(
    {   
        type='text',
        textarea=false,
        rows=2,
        autoFocus=false,
        outline=true,
        isEdit, setIsEdit,
        text, saveValue,
        editHeader='',
        placeholder,
        saveButton,
        cancelButton,
        editButton=true,
        modal=false,
        editEmptyByDefault=false
    } ) {
    const [value, setValue] = useState('')
    const inputRef = useRef(null)

    useEffect(() => {
        setValue(text)
    }, [text])

    useEffect(() => {
        if (inputRef.current === null) {
            return
        }

        inputRef.current.addEventListener('keypress', (e) => {
            if (e.key === 'Enter' && isEdit) {
                saveAndClose()
            }
        })

    })

    const plainText = (
        <MDBRow className={'d-flex align-items-center'}>
            <MDBCol size={editButton === true ? 10 : 12}
                    onClick={(event) => {
                        if (event.detail >= 2) {
                            setIsEdit(true)
                        }
                    }}>
                <span>
                    {text}
                </span>
            </MDBCol>
            {editButton === true && (
                <MDBCol size={2} className={'d-flex align-items-center'}>
                    <a href={"#"} onClick={() => setIsEdit(true)}>
                        <MDBIcon fas icon="edit"/>
                    </a>
                </MDBCol>
            )}
        </MDBRow>
    )

    const inputProps = {
        ref: inputRef,
        autoFocus: autoFocus,
        type: type,
        className: 'w-100 tg-secondary-bg' + (outline === false ? ' input-no-outline p-0' : ' form-control'),
        value: value,
        placeholder: placeholder,
        'onChange': event => setValue(event.target.value)
    }

    const editInput = textarea ? <textarea rows={rows} {...inputProps}/> :  <input {...inputProps}/>

    const saveAndClose = () => {
        saveValue(value)
        setIsEdit(false)
    }

    const close = () => {
        setValue(text)
        setIsEdit(false)
    }

    if (modal === true) {
        return <EditModal
            text={text}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            saveValue={saveAndClose}
            close={close}
            plainText={plainText}
            editInput={editInput}
            editHeader={editHeader}
            saveButton={saveButton}
            cancelButton={cancelButton}
        />
    }

    return <EditInput
        isEdit={isEdit}
        outline={outline}
        setIsEdit={setIsEdit}
        value={value}
        saveValue={saveAndClose}
        close={close}
        plainText={plainText}
        editInput={editInput}
        text={text}
        editEmptyByDefault={editEmptyByDefault}
    />

}
