import EditableText from "../editableText";
import {useState} from "react";
import {MDBContainer} from "mdb-react-ui-kit";


export function EditSlotForm(
    {
        trelloLink, taskName, comments,
        saveTrelloLink, saveTaskName, saveComment,
    }
) {
    const [isEditTrello, setIsEditTrello] = useState(false)
    const [isEditName, setIsEditName] = useState(false)
    const [isEditComment, setIsEditComment] = useState(false)

    return (
        <MDBContainer>
            <EditableText
                type={'url'}
                isEdit={isEditTrello}
                setIsEdit={setIsEditTrello}
                text={trelloLink||''}
                saveValue={saveTrelloLink}
                placeholder={'Trello link...'}
                editEmptyByDefault={true}
            />
            {!trelloLink && (
                <>
                    <hr/>
                    <EditableText
                        isEdit={isEditName}
                        setIsEdit={setIsEditName}
                        text={taskName||''}
                        saveValue={saveTaskName}
                        placeholder={'Name...'}
                        editEmptyByDefault={true}
                    />
                </>
            )}

            <hr/>

            <EditableText
                textarea
                rows={4}
                isEdit={isEditComment}
                setIsEdit={setIsEditComment}
                text={comments||''}
                saveValue={saveComment}
                placeholder={'Slot comment...'}
                editEmptyByDefault={true}
            />

        </MDBContainer>
    )

}
