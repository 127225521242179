
import api from '../api'

const fetchOwners = async(chatID=null) => {
    try {
        return (await api.tasks.getOwners(chatID)).data
    } catch (ex) {
        console.log(ex)
        return {error: `/groups ${ex.response.data.detail ? ex.response.data.detail : 'Error while getting data from API'}`}
    }
}

const fetchGroups = async(ownerID, filters) => {
    try{
        return (await api.tasks.getGroups(ownerID, filters)).data
    }
    catch(ex){
        console.log(ex)
        return {error: `/groups ${ex.response.data.detail ? ex.response.data.detail : 'Error while getting data from API'}`}
    }
}

const fetchTasks = async(ownerID, users, groups, filters) => {
    try{
        return (await api.tasks.getTasks(ownerID, users, groups, filters)).data
    }
    catch(ex){
        console.log(ex)
        return {error: `/tasks ${ex.response.data.detail ? ex.response.data.detail : 'Error while getting data from API'}`}
    }
}

const fetchUsers = async(ownerID, tasks, groups, filters) => {
    try{
        return (await api.tasks.getUsers(ownerID, tasks, groups, filters)).data
    }
    catch(ex){
        //TODO: alert
        console.log(ex)
        return {error: `/users ${ex.response.data.detail ? ex.response.data.detail : 'Error while getting data from API'}`}
    }
}

const fetchData = async(ownerID, users, tasks, groups, filters) => {
    try{
        return (await api.tasks.getData(ownerID, users, tasks, groups, filters)).data
    }
    catch(ex){
        console.log(ex)
        return {error: `/data ${ex.response.data.detail ? ex.response.data.detail : 'Error while getting data from API'}`}
    }
}

const fetchDates = async(ownerID, users, tasks, groups, filters) => {
    try{
        return (await api.tasks.getMinMaxDates(ownerID, users, tasks, groups, filters)).data
    }
    catch(ex){
        //TODO: alert
        console.log(ex)
        return {error: `/dates ${ex.response.data.detail ? ex.response.data.detail : 'Error while getting data from API'}`}
    }
}

export { fetchOwners, fetchTasks, fetchUsers, fetchData, fetchDates, fetchGroups, }