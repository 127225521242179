import { deepCopy } from '../utils/index'


export default function tasksApiModule(instance){

    //tasks api help functions
    const getMdbSelectedArrayParams = (arr) => {
        if(arr !== undefined){
            const defaultSelected = deepCopy(arr).filter(x => parseInt(x) > 0)
            return defaultSelected.filter(x => x !== undefined)
        }
        return []
    }

    const removeBlankFiltersArgs = (args) => {
        const qArgs = deepCopy(args)
        if(qArgs.chat_id === null || qArgs.chat_id === 0 || qArgs.chat_id === ""){
            delete qArgs["chat_id"]
        }
        if(qArgs.start_date === undefined || qArgs.start_date === ""){
            delete qArgs["start_date"]
        }
        if(qArgs.end_date === undefined || qArgs.end_date === ""){
            delete qArgs["end_date"]
        }

        return qArgs
    }

    //api queries
    return{
        getData(ownerID, users, tasks, groups, args){
            const qArgs = removeBlankFiltersArgs(args)
            qArgs["owner_id"] = ownerID
            qArgs["users"] = getMdbSelectedArrayParams(users)
            qArgs["tasks"] = getMdbSelectedArrayParams(tasks)
            qArgs["groups"] = getMdbSelectedArrayParams(groups)

            return instance.get('data', {params: qArgs})
        },

        getTasks(ownerID, users, groups, args){
            const qArgs = removeBlankFiltersArgs(args)
            qArgs["owner_id"] = ownerID
            qArgs["users"] = getMdbSelectedArrayParams(users)
            qArgs["groups"] = getMdbSelectedArrayParams(groups)

            return instance.get('tasks', {params: qArgs})
        },

        getCommitInfo(id){
            return instance.get(`commits/details/${id}`)
        },

        getMinMaxDates(ownerID, users, tasks, groups, args){
            const qArgs = removeBlankFiltersArgs(args)
            qArgs["owner_id"] = ownerID
            qArgs["users"] = getMdbSelectedArrayParams(users)
            qArgs["tasks"] = getMdbSelectedArrayParams(tasks)
            qArgs["groups"] = getMdbSelectedArrayParams(groups)

            return instance.get('dates', {params: qArgs})
        },

        getUsers(ownerID, tasks, groups, args){
            const qArgs = removeBlankFiltersArgs(args)
            qArgs["owner_id"] = ownerID
            qArgs["tasks"] = getMdbSelectedArrayParams(tasks)
            qArgs["groups"] = getMdbSelectedArrayParams(groups)

            return instance.get('users', {params: qArgs})
        },

        getGroups(ownerID, args){
            return instance.get('groups', {params: {...args, owner_id: ownerID}})
        },

        getOwners(chatID=null) {
            const qArgs = removeBlankFiltersArgs({chat_id: chatID})
            return instance.get('owners', {params: qArgs})
        }
    }
}