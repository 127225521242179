import axios from 'axios'
import { backendUrl } from '../constants/index'
import Qs from 'qs'

const instance = axios.create({
    baseURL: backendUrl,
    paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'}),
    headers: {
      "content-type": "application/json",
      "Accept": "application/json"
    }
})

instance.defaults.headers.common = {
  'TGAuthorization': window.Telegram.WebApp.initData,
}

export default instance