import {AxiosInstance} from "axios";

export type SetUserTimezoneResultType = {
    timezone: string
}


export type User = {
    id: number
    chat_id: number
    full_name: string
    username: string | null
    timezone: string
    lang: string
}


export default function usersApiModule(instance: AxiosInstance){
    return {
        getMe() {
            return instance.get<User>('users/me')
        },
        setTimezone(newTimezone: string, messageID?: number | string | null) {
            return instance.post<SetUserTimezoneResultType>('users/setTimezone', {
                new_timezone: newTimezone,
                message_id: messageID || null
            })
        }
    }
}
