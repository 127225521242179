export default function slotsApiModule(instance){
    //api queries
    return {
        updateComment(slotID, comment){
            return instance.post('slots/update_comment', {
                slot_id: slotID,
                comment: comment,
            })
        },

        updateTrello(slotID, trelloLink){
            return instance.post('slots/update_trello', {
                slot_id: slotID,
                trello_link: trelloLink,
            })
        },

        updateName(slotID, name){
            return instance.post('slots/update_name', {
                slot_id: slotID,
                name: name,
            })
        },
    }
}
