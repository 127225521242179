import {useEffect, useState} from "react";


export const useOwnerID = (initialValue: number | null) => {
    const [ownerID, setOwnerIDState] = useState(initialValue)

    useEffect(() => {
        let value = window.localStorage.getItem('ownerID')

        if (!value || !parseInt(value)) {
            if (initialValue) {
                setOwnerID(initialValue)
            } else {
                removeOwnerID()
            }
        } else {
            setOwnerID(parseInt(value))
        }

    }, [])

    const setOwnerID = (value: number) => {
        window.localStorage.setItem("ownerID", value.toString())
        setOwnerIDState(value)
    }

    const removeOwnerID = () => {
        window.localStorage.removeItem("ownerID")
        setOwnerIDState(null)
    }

    return [ownerID, {setOwnerID, removeOwnerID}]
}
