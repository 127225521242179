import React, {useState} from 'react'
import {
    MDBBtn,
    MDBDatatable,
    MDBIcon,
} from 'mdb-react-ui-kit'

import TaskTableFilters from './TaskTableFilters'
import CommitModal from './CommitModal'
import {EditSlotModal} from "../edit-slot/modal";


function TasksTable( 
    {
        ownerID,
        selectedGroups,
        users, tasks,
        data,
        allowedOwners,
        allowedGroups,
        allowedUsers,
        allowedTasks,
        minMaxDates,
        filters,
        onOwnerFilterChange,
        onGroupsFilterChange,
        onUsersFilterChange,
        onTasksFilterChange,
        onStartDateFilterChange,
        onEndDateFilterChange,
        errors, columns,
        saveComment,
        saveTaskName,
        saveTrelloLink,
    } 
) {
    const [showCommitModal, setShowCommitModal] = useState(false)
    const [selectedCommitId, setSelectedCommitID] = useState(null)

    const [showEditModal, setShowEditModal] = useState(false)
    const [selectedSlotIDX, setSelectedSlotIDX] = useState(null)

    return (
        <>
            {/* filters */}
            <TaskTableFilters
                ownerID={ownerID}
                selectedGroups={selectedGroups}
                users={users}
                tasks={tasks}
                allowedOwners={allowedOwners}
                allowedGroups={allowedGroups}
                allowedUsers={allowedUsers}
                allowedTasks={allowedTasks}
                minMaxDate={minMaxDates} 
                filters={filters}
                onOwnerFilterChange={onOwnerFilterChange}
                onGroupsFilterChange={onGroupsFilterChange}
                onUsersFilterChange={onUsersFilterChange}
                onTasksFilterChange={onTasksFilterChange}
                onStartDateFilterChange={onStartDateFilterChange}
                onEndDateFilterChange={onEndDateFilterChange}
                errors={errors}
            />

            {/* table */}
            {data
            ?
            <MDBDatatable
                entries={200}
                sortField={'date_task_comments'}
                data={{
                    columns: columns,
                    rows:  data.map((row, row_idx) => {
                        return {
                            name: row.name,
                            git: (
                                <div>
                                    <span>{row.lines_and_files + '\n'}</span>
                                    {row.trello_link && (
                                        <a
                                            href={row.trello_link}
                                            target={'_blank'}
                                            style={{textDecoration: 'underline'}}
                                        >
                                            {'trello\n'}
                                        </a>
                                    )}
                                    {row.commits.map(commit => (
                                        <>
                                            <span
                                                key={`span-${commit.id}`}
                                                className={'fake-link break-word pre-wrap'}
                                                onClick={() => {
                                                    setSelectedCommitID(commit.id)
                                                    setShowCommitModal(true)
                                                }}
                                            >
                                                {commit.time + '\n'}
                                            </span>
                                        </>
                                    ))}
                                    <MDBBtn
                                        className={'btn-floating'}
                                        onClick={() => {
                                            setSelectedSlotIDX(row_idx)
                                            setShowEditModal(true)
                                        }}
                                    >
                                        <MDBIcon
                                            far icon="edit" className={'me-1'} size={'2xs'}
                                        />
                                    </MDBBtn>
                                </div>
                            ),
                            date_task_comments: (
                                row.time + '\n'
                                + row.task_name
                                + (row.comments ? ` | ${row.comments}` : '')
                            ),
                        }
                    }).sort((a, b) => {
                        return a.date_task_comments > b.date_task_comments
                    }),
                }}
                format={() => {
                    return { whiteSpace: 'pre-wrap', wordBreak: 'pre-line' }
                }}
                search
                noFoundMessage={
                    errors.isErrors 
                    ?
                        errors.dataError !== "" 
                        ?
                            <div className='text-danger'>{errors.dataError}</div>
                        :
                        'No matching results found'
                    :
                        'No matching results found'
                }
                // isLoading={loading}
                 />
                 :
                 null
            }
            

            {/* commit modal */}
            {selectedSlotIDX !== null && (
                <EditSlotModal
                    show={showEditModal}
                    setShow={setShowEditModal}
                    trelloLink={data[selectedSlotIDX].trello_link}
                    taskName={data[selectedSlotIDX].task_name}
                    comments={data[selectedSlotIDX].comments}
                    saveTrelloLink={trelloLink => saveTrelloLink(selectedSlotIDX,  trelloLink)}
                    saveComment={comment => saveComment(selectedSlotIDX, comment)}
                    saveTaskName={name => saveTaskName(selectedSlotIDX, name)}
                />
            )}
            <CommitModal id={selectedCommitId} show={showCommitModal} setShowCommitModal={setShowCommitModal} /> 
        </>
    )
}

export default TasksTable