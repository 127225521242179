import React, {useEffect, useRef, useState} from 'react'
import { Outlet } from "react-router-dom"
import { MDBContainer } from 'mdb-react-ui-kit'
import { useSearchParams } from 'react-router-dom'
import { useMDBStorage } from 'mdb-react-storage-management'

import { getBaseTgData, trySetThemeColorByTgColorSchema, baseSetupTg } from '../../utils/tg'
import Header from '../../components/base/Header'
import instance from '../../api/instance'

window.Telegram.WebApp.ready()
const baseTgData = getBaseTgData()

function Layout() {
    const [searchParams, setSearchParams] = useSearchParams()

    const [reload, setReload] = useState(0)
    const [initData, {setItem}] = useMDBStorage('init_data', '')

    const [needShort, setNeedShort] = useState(false)

    const [headerText, setHeaderText] = useState('')
    const [isSecondaryBg, setIsSecondaryBg] = useState(false)

    const openInWebUrlRef = useRef(window.location.origin)

    const setOpenInWebUrl = newUrl => {
        openInWebUrlRef.current = newUrl
    }

    const initDataParam = searchParams.get("init_data")
    const apiKey = searchParams.get('api_key')

    if (apiKey){
        instance.defaults.params = {'api_key': apiKey}
    } else if(initData){
        instance.defaults.headers.common['TGAuthorization'] = initData
    }

    useEffect(() => {
        if(initDataParam){
            searchParams.delete('init_data')
            setSearchParams(searchParams)
            setItem(initDataParam)
        }
    }, [initDataParam])

    useEffect(() => {
        trySetThemeColorByTgColorSchema()
        baseSetupTg()
    }, [])

    useEffect(() => {
        const el = document.getElementsByTagName('body')[0]
        el.classList.remove('tg-bg')
        el.classList.remove('tg-secondary-bg')
        el.classList.add(isSecondaryBg ? 'tg-secondary-bg' : 'tg-bg')
    }, [isSecondaryBg])

    return (
        <>
            <Header
                isTelegram={baseTgData.tgUserChatId !== 0}
                reload={reload} setReload={setReload}
                openInWebUrlRef={openInWebUrlRef}
                needShort={needShort} headerText={headerText}
            />
            <MDBContainer fluid className={'mt-2 p-1'}>
                <Outlet context={{
                    baseTgData: baseTgData,
                    reload: reload, setReload: setReload,
                    setOpenInWebUrl: setOpenInWebUrl,
                    needShort: needShort,
                    setNeedShort: setNeedShort,
                    initData: initData,
                    headerText, setHeaderText,
                    isSecondaryBg, setIsSecondaryBg,
                }}/>
            </MDBContainer>
        </>
    )
}

export default Layout