

// columns for MDBootstrap DataTable
const getColumns = () => {
    const windowWidth = window.innerWidth

    let nameWidth = windowWidth / 4;
    let commitsWidth = windowWidth / 4;
    let dateTaskComments = windowWidth / 2;

    if ( nameWidth > 150 ) {
        nameWidth = 150
    }
    if ( commitsWidth > 50 ) {
        commitsWidth = 50
    }

    return [
        { label: 'Name', field: 'name', sort: true, width: nameWidth },
        { label: 'Info', field: 'git', sort: false, width: commitsWidth },
        { label: 'Task', field: 'date_task_comments', sort: true, width: dateTaskComments },
    ]
}


export { getColumns }