import api from "../api";

const fetchAccessUsers = async() => {
    try {
        return (await api.accessUsers.getAccessUsers()).data
    } catch (ex) {
        console.log(ex)
        return {error: `/groups ${ex.response.data.detail ? ex.response.data.detail : 'Error while getting data from API'}`}
    }
}

export {fetchAccessUsers};
