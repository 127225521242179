export default function accessUsersApiModule(instance){
    //api queries
    return {
        getAccessUsers(){
            return instance.get('access_users/')
        },
        updateAccessUser(id, data){
            // data = {name, email, sheet_url, must_commit}
            return instance.patch(`access_users/${id}`, {
                name: data.name,
                email: data.email,
                sheet_id: data.sheet_url,
                must_commit: data.must_commit,
            })
            // returns all {name, email, sheet_url, must_commit} with new values
        },
        createAccessor(accessUserID, accessorUserID) {
            return instance.post(`access_users/accessors/create`, {
                access_user_id: accessUserID,
                accessor_user_id: accessorUserID,
            })
        },
        updateAccessor(id, data) {
            return instance.patch(`access_users/accessors/${id}`, {
                notify_commits: data.notify_commits,
                notify_slots: data.notify_slots,
            })
        },
        deleteAccessUser(id) {
            return instance.delete(`access_users/${id}`)
        },
        deleteAccessor(id){
            return instance.delete(`access_users/accessors/${id}`)
        },
        getLocalisation() {
            return instance.get(`access_users/localisation`)
        },
        getUsersToAddAccessor(id, search) {
            return instance.get(`access_users/${id}/usersToAddAccessor`, {
                params: {search: search}
            })
        }
    }
}
