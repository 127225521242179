import instance from './instance'
import tasksApiModule from './tasks'
import slotsApiModule from "./slots";
import accessUsersApiModule from "./accessUsers";
import usersApiModule from "./users";

const api = {
    instance,
    tasks: tasksApiModule(instance),
    slots: slotsApiModule(instance),
    accessUsers: accessUsersApiModule(instance),
    users: usersApiModule(instance),
}

export default api