import {
    MDBCol,
    MDBContainer,
    MDBDatepicker,
    MDBRow,
    MDBSelect,
} from 'mdb-react-ui-kit'

function TaskTableFilters(
    {
        filters,
        ownerID,
        selectedGroups,
        tasks, users,
        minMaxDate,
        allowedOwners,
        allowedGroups,
        allowedTasks,
        allowedUsers,
        errors,
        onOwnerFilterChange,
        onGroupsFilterChange,
        onUsersFilterChange,
        onTasksFilterChange,
        onStartDateFilterChange,
        onEndDateFilterChange,
    } ) {
    const minDate = minMaxDate.min_date ? new Date(minMaxDate.min_date) : undefined
    if (minDate) {
        minDate.setDate(minDate.getDate()-1)
    }
    const maxDate = minMaxDate.max_date ? new Date(minMaxDate.max_date) : undefined
    if (maxDate) {
        maxDate.setDate(maxDate.getDate()+1)
    }


    return (
        <MDBContainer fluid className={'p-0'}>
            <MDBRow className={'m-0 mb-2 w-100'}>

                {/* date period filter */}
                <MDBCol size={'12'} lg={'6'} className={'my-2 p-0'}>
                    <MDBDatepicker
                        aria-describedby={'start_date_error'}
                        inputLabel={'Select start date'}
                        format={'yyyy-mm-dd'}
                        min={minDate} max={maxDate}
                        value={filters.start_date ? filters.start_date : undefined}
                        placeholder={filters.start_date ? filters.start_date : undefined}
                        onChange={(e) => onStartDateFilterChange(e)}
                        // onChange={async(e) => await onFilterValueChange("start_date", e)}
                        />
                    <div className={'small text-danger'} id={'start_date_error'}>
                        {errors.isErrors ? errors.datesError !== "" ? errors.datesError : null : null}
                    </div>
                </MDBCol>
                <MDBCol size={'12'} lg={'6'} className={'my-2 p-0'}>
                    <MDBDatepicker
                        aria-describedby={'end_date_error'}
                        inputLabel={'Select end date'}
                        format={'yyyy-mm-dd'}
                        min={minDate} max={maxDate}
                        value={filters.end_date ? filters.end_date : undefined}
                        placeholder={filters.end_date ? filters.end_date : undefined}
                        onChange={(e) => onEndDateFilterChange(e)}
                        // onChange={async(e) => await onFilterValueChange("end_date", e)}
                        />
                    <div className={'small text-danger'} id={'end_date_error'}>
                        {errors.isErrors ? errors.datesError !== "" ? errors.datesError : null : null}
                    </div>
                </MDBCol>

                </MDBRow>
                <MDBRow className={'m-0 mb-2 w-100 p-0'}>

                {/* owner filter */}
                {allowedOwners && allowedOwners.length > 1 ? (
                    <MDBCol size={'12'} lg={'4'} className={'my-1 p-1'}>
                        <MDBSelect
                            autoSelect={false}
                            aria-describedby={'owner_error'}
                            label={'Select owner'}
                            data={
                                allowedOwners.map(i => {
                                    return {
                                        value: i.id,
                                        text: i.name,
                                        defaultSelected: i.id === ownerID,
                                    }
                                })
                            }
                            onValueChange={(e) => onOwnerFilterChange(e)}
                            />
                        <div className={'small text-danger'} id={'owner_error'}>
                            {errors.isErrors ? errors.ownerError !== "" ? errors.ownerError : null : null}
                        </div>
                    </MDBCol>
                ) : null}

                {/* groups filter */}
                {allowedGroups ? (
                    <MDBCol size={'12'} lg={'4'} className={'my-1 p-1'}>
                        <MDBSelect
                            selectAll={false}
                            clearBtn
                            aria-describedby={'groups_error'}
                            label={'Select groups'}
                            multiple
                            data={
                                allowedGroups.map(i => {
                                    return {
                                        value: i.id,
                                        text: i.name,
                                        defaultSelected: selectedGroups.includes(i.id)
                                    }
                                })
                            }
                            disabled={allowedGroups.length < 1}
                            onValueChange={(e) => onGroupsFilterChange(e)}
                            />
                        <div className={'small text-danger'} id={'groups_error'}>
                            {errors.isErrors ? errors.groupsError !== "" ? errors.groupsError : null : null}
                        </div>
                    </MDBCol>
                ) : null}

                {/* users filter */}
                {allowedUsers ?(
                    <MDBCol size={'12'} lg={'4'} className={'my-1 p-1'}>
                        <MDBSelect
                            selectAll={false}
                            clearBtn
                            aria-describedby={'users_error'}
                            multiple
                            label={'Select users'}
                            data={
                                allowedUsers.map(i => {
                                    return {
                                        value: i.id,
                                        text: i.name,
                                        defaultSelected: users.includes(i.id)
                                    }
                                })
                            }
                            disabled={allowedUsers.length < 1}
                            onValueChange={(e) => onUsersFilterChange(e)}
                            />
                        <div className={'small text-danger'} id={'users_error'}>
                            {errors.isErrors ? errors.usersError !== "" ? errors.usersError : null : null}
                        </div>
                    </MDBCol>
                ) : null}

                {/* tasks filter */}
                {allowedTasks ? (
                    <MDBCol size={'12'} lg={'4'} className={'my-1 p-1'}>
                        <MDBSelect
                            selectAll={false}
                            clearBtn
                            aria-describedby={'tasks_error'}
                            label={'Select tasks'}
                            multiple
                            data={
                                allowedTasks.map(i => {
                                    return {
                                        value: i.id,
                                        text: i.name,
                                        defaultSelected: tasks.includes(i.id)
                                    }
                                })
                            }
                            disabled={allowedTasks.length < 1}
                            onValueChange={(e) => onTasksFilterChange(e)}
                            />
                        <div className={'small text-danger'} id={'tasks_error'}>
                            {errors.isErrors ? errors.tasksError !== "" ? errors.tasksError : null : null}
                        </div>
                    </MDBCol>
                ) : null}

            </MDBRow>
        </MDBContainer>
    )
}

export default TaskTableFilters
