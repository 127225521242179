import {
    MDBIcon, MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalHeader,
    MDBModalTitle
} from "mdb-react-ui-kit";
import {EditSlotForm} from "./form";


export function EditSlotModal(
    {
        show, setShow,
        trelloLink, taskName, comments,
        saveTrelloLink, saveTaskName, saveComment,
    }
) {
    return (
        <MDBModal show={show} setShow={setShow} tabIndex='-1' className={'tg-modal'}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>{'Editing slot task and comment'}</MDBModalTitle>
                      <a href={"#"} onClick={() => setShow(false)}>
                          <MDBIcon fas icon="times" size={'2x'}/>
                      </a>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <EditSlotForm
                            trelloLink={trelloLink}
                            taskName={taskName}
                            comments={comments}
                            saveTrelloLink={saveTrelloLink}
                            saveTaskName={saveTaskName}
                            saveComment={saveComment}
                        />
                    </MDBModalBody>
                </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    )
}
