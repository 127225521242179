import {
    MDBBtn,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
} from 'mdb-react-ui-kit'
import React, { useState, useEffect, useRef  } from 'react'

import api from '../../api'

function CommitModal(props) {
    const [commitInfo, setCommitInfo] = useState(null)
    const [commitLink, setCommitLink] = useState(null)
    const modal = useRef()

    const toggleShow = () => {
        props.setShowCommitModal(!props.show)
    }

    const fetchCommitDetails = async() => {
        return (await api.tasks.getCommitInfo(props.id)).data
    }

    useEffect(() => {
        if(props.show === true && props.id){
            fetchCommitDetails().then((data) => {
             setCommitInfo(data.details)
             setCommitLink(data.gitlab_link)
            })
        }
    }, [props.id, props.show])

    return (
        <div>
            <MDBModal appendToBody={true}
                      className={'tg-modal'}
                setShow={props.setShowCommitModal}
                show={props.show}
                tabIndex='-1'
                modalRef={modal}>
                <MDBModalDialog scrollable>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Commit details</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className={'pre-wrap break-word'}>
                                {commitInfo ? commitInfo : <strong>Can't get information about commit</strong>}
                            </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                            {
                                commitLink !== null &&
                                <MDBBtn className={'text-white'} target="_blank" href={commitLink}>
                                    GitLab <MDBIcon fas icon="external-link-alt" />
                                </MDBBtn>
                            }
                            <MDBBtn color='secondary' onClick={toggleShow}>
                                Close
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>

    )
}

export default CommitModal
