//returns an object with necessary data from WebApp initDataUnsafe to use in context
const getBaseTgData = () => {
    const WebApp = window.Telegram.WebApp
    const tgUser = WebApp.initDataUnsafe.user
    const tgUserChatId = tgUser ? tgUser.id : 0

    return {
        tgUserChatId: tgUserChatId
    }
}

const baseSetupTg = () => {
    window.Telegram.WebApp.expand()
}

//sets css theme variables by a telegram themeParams object if it exists
const trySetThemeColorByTgColorSchema = () => {
    const scheme = window.Telegram.WebApp.themeParams
    const root = document.documentElement
    setThemeVariableIfExist(root, scheme.bg_color, '--tg-background-color')
    setThemeVariableIfExist(root, scheme.button_color, '--tg-button-color')
    setThemeVariableIfExist(root, scheme.button_text_color, '--tg-button-text-color')
    setThemeVariableIfExist(root, scheme.hint_color, '--tg-hint-color')
    setThemeVariableIfExist(root, scheme.link_color, '--tg-link-color')
    setThemeVariableIfExist(root, scheme.secondary_bg_color, '--tg-secondary-bg-color')
    setThemeVariableIfExist(root, scheme.text_color, '--tg-text-color')
}

//helper function for setThemeColorByTgColorSchema,
//maybe it should be in some basic global utils module later
const setThemeVariableIfExist = (root, tgVal, varName) => {
    if(tgVal){
        root.style.setProperty(varName, tgVal)
    }
}

export { getBaseTgData, trySetThemeColorByTgColorSchema, baseSetupTg }
