import {MDBBtn, MDBIcon} from "mdb-react-ui-kit";
import TimezoneSelect, {ITimezoneOption, allTimezones} from "react-timezone-select";
import {useEffect, useState} from "react";
import api from "../api";
import useMe from "../hooks/useMe";
import {User} from "../api/users";
import {useSearchParams} from "react-router-dom";


export default function TimezonePickerPage() {
    const [me] = useMe()

    return <Component me={me}/>
}


function Component({me}: {me: User}) {
    const [selectedTimezone, setSelectedTimezone] = useState<ITimezoneOption>({
        value: Intl.DateTimeFormat().resolvedOptions().timeZone,
        label: allTimezones[Intl.DateTimeFormat().resolvedOptions().timeZone],
    })
    const [searchParams] = useSearchParams()

    useEffect(() => {
        if (Object.keys(allTimezones).includes(me.timezone)) {
            setSelectedTimezone({
                value: me.timezone,
                label: allTimezones[me.timezone],
            })
        }
    }, [me.timezone])

    return (
        <div className={'d-flex justify-content-center mt-5'}>
            <div>
                <div className={'text-center'}>
                    Current timezone: {me.timezone || '-'}
                </div>

                <div className={'d-flex'}>
                    <div style={{width: '300px'}}>
                        <TimezoneSelect
                            value={selectedTimezone}
                            className={'text-dark'}
                            onChange={setSelectedTimezone}
                            labelStyle={'original'}
                            styles={{
                                control: provided => ({
                                    ...provided,
                                    color: 'black',
                                }),
                                input: provided => ({
                                    ...provided,
                                    '[type="text"]': {
                                        color: 'black!important'
                                    }
                                }),
                                valueContainer: provided => ({
                                    ...provided,
                                    color: 'black',
                                }),
                                singleValue: provided => ({
                                    ...provided,
                                    color: 'black',
                                }),
                                menuList: provided => ({
                                    ...provided,
                                    overflow: 'scroll!important',
                                }),
                            }}
                        />
                    </div>
                    <MDBBtn className={'ms-2'} onClick={() => {
                        api.users.setTimezone(
                            selectedTimezone.value,
                            searchParams.get("message_id"),
                        ).then(() => {
                            // @ts-ignore
                            window.Telegram.WebApp.close()
                        })
                    }}>
                        <MDBIcon icon={'fa fa-check'} size={'1x'}/>
                    </MDBBtn>
                </div>
            </div>
        </div>
    )
}
