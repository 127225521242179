import {Dispatch, SetStateAction, useEffect, useState} from "react";
import api from "../api";
import {User} from "../api/users";
import {AxiosResponse} from "axios";


const resolveError = (response: AxiosResponse) => {
    if (response.data) {
        return response.data.detail;
    }
    if (response.status === 500) {
        return "Internal Server Error";
    }
    return 'Api Connection Error'
}


export default function useMe(): [User, Dispatch<SetStateAction<User | {}>>, string | null, () => void] {
    const [me, setMe] = useState<User | {}>({})
    const [error, setError] = useState<string | null>(null)

    const [reloadCounter, setReloadCounter] = useState(0)

    const reload = () => setReloadCounter(prev => prev+1)

    useEffect(() => {
        api.users.getMe().then(
            response => {
                if (response.status === 200) {
                    setError(null)
                    setMe(response.data)
                } else {
                    setError(resolveError(response))
                }
            }
        ).catch(error => {
            console.log(error)
            setError(resolveError(error.response))
        })
    }, [reloadCounter])

    return [me as User, setMe, error, reload]
}
